import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Context } from '../..';
import BgItem from '../../components/BgItem/BgItem';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import FeedbackItem from '../../components/FeedbackItem/FeedbackItem';
import { fetchAllFeedbackCheck } from '../../http/feedbackApi';
import st from './About.module.scss'

const About = observer(() => {
    const { feedback } = useContext(Context)
    useEffect(() => {
        fetchAllFeedbackCheck(1, 10).then(data => feedback.setCheckedFeedback(data.rows))
    }, [feedback])
    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <div className={st.mainBlockDescription}>
                    <div className={st.descriptBlock}>
                        <div className={st.title}>Не много о нашем магазине</div>
                        <div className={st.descript}><span>ПРОКАТ ИНСТРУМЕНТА В САРАНСКЕ НА УЛИЦЕ ПРОЛЕТАРСКОЙ</span>
                            <br></br>
                            Приобретение дорогостоящего инструмента и оборудования
                            для сезонных строительных работ или капитального ремонта в
                            квартире – не очень выгодное решение. Тем более что перечень
                            технических средств, которые могут понадобиться, является достаточно
                            большим. В таких случаях выручит аренда инструмента в Саранске и облости
                            города на выгодных условиях предоставляет наша компания </div>
                    </div>
                    <FeedbackForm />
                </div>
                <div className={st.mainBlockFeedback}>
                    <div className={st.mainBlockTitle}>Отзывы наших клиентов</div>
                    <div className={st.mainBlockFeed}>
                        {
                            feedback.checkedFeedback.map(f =>
                                <FeedbackItem fedd={f} key={f.feedback} />
                            )
                        }
                    </div>
                </div>
                <BgItem width='50%' style={{ top: '60%', left: '0%' }} />
                <BgItem style={{ top: '-10%', right: '0%' }} variant='car' />
            </div>
        </div>
    )
})
export default About;