import { YMaps, Map, Placemark } from 'react-yandex-maps';
import flag from '../../assets/img/svg/telephone.svg';

const CardMap = () => {
  const mapData = {
    center: [54.20570167721856, 45.1794825476074],
    zoom: 16,
  };
  const mapOptions = {
    iconLayout: 'default#image',
    iconImageHref: flag,
    iconImageSize: [50, 100],
    iconImageOffset: [-30, -90],
  };
  const coordinate = [54.205921809684654, 45.18054470237731];

  return (
    <YMaps>
      <Map height="100%" width="100%" defaultState={mapData}>
        <Placemark geometry={coordinate} options={mapOptions} />
      </Map>
    </YMaps>
  );
};

export default CardMap;
