import st from './Footer.module.scss'
import logo2 from '../../assets/img/logo/logo2.png'
import logo2smFooter from '../../assets/img/logo/logo2smFooter.png'
import Menu from '../menu/Menu';
import Search from '../Search/Search';
import BgItem from '../BgItem/BgItem';
import { MAIN_ROUTE } from '../../utils/constance';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer className={st.footer}>
            <div className={st.footerContnier}>
                <BgItem variant='car' style={{ top: '-3%', right: '5%' }} />
                <div className={st.loginBlock}>
                    <div className={st.topLine}>
                        <div onClick={() => navigate(MAIN_ROUTE)} className={st.logo}>
                            <img className={st.logoFooter} src={logo2} alt="" />
                            <img className={st.logoSmFooter} src={logo2smFooter} alt="" />
                        </div>
                        <Menu />
                    </div>
                    <div className={st.searchLine}>
                        <Search />
                    </div>
                </div>
                <div className={st.contactsBlock}>
                    <div className={st.number}>{process.env.REACT_APP_PHONE}</div>
                    <div className={st.working}>Режим работы с 8 : 00 до 18 : 00 <br /> БЕЗ ПЕРЕРЫВА И ВЫХОДНЫХ</div>
                    <div className={st.adress}>г. Саранск <br /> ул. Пролетарская дом 121</div>
                </div>
                <div className={st.rightBlock}>
                    <span>® All rights reserved ПРОКАТ ИНСТРУМЕНТА 2022</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;