import { Button, Modal } from "react-bootstrap";
import st from './ModalUpdateTool.module.scss'

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <h4>Заказать инструмент</h4>
        <p>
          Для заказа или доставки инструмента позвоните по телефону <span className={st.spanNumber}>{process.env.REACT_APP_PHONE}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='warning' onClick={props.onHide}>Закрыть</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
