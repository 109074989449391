// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalUpdateTool_spanNumber__47b6l {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/ModalUpdateTool/ModalUpdateTool.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ","sourcesContent":[".spanNumber {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spanNumber": "ModalUpdateTool_spanNumber__47b6l"
};
export default ___CSS_LOADER_EXPORT___;
