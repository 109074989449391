import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BgItem from '../../components/BgItem/BgItem'
import Button from '../../components/Button/Button'
import MyVerticallyCenteredModal from '../../components/modals/ModalUpdateTool/ModalUpdateTool'
import MyVideodModal from '../../components/modals/ModalVideo/MyVideodModal'
import { fetchOneItem, fetchOneType } from '../../http/itemApi'
import st from './Item.module.scss'
import play from '../../assets/img/svg/play.svg'
import { CATALOG_ROUTE } from '../../utils/constance'
import SocialLink from '../../components/SocialLink/SocialLink'

const Item = observer(() => {
    const navigate = useNavigate()
    const [item, setItem] = useState({ info: [] })
    const { id } = useParams()
    const [mainPhoto, setMainPhoto] = useState('')
    const [mainVideo, setMainVIdeo] = useState('')
    const [type, setType] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [modalVideoShow, setModalVIdeoShow] = useState(false);

    const startVideo = (path) => {
        setMainVIdeo(path)
        setModalVIdeoShow(true)
        console.log(mainVideo)
    }

    useEffect(() => {
        if (item.typeId) {
            fetchOneType(item.typeId).then(data => setType(data.name))
        }
    }, [item.typeId])
    useEffect(() => {
        fetchOneItem(id).then(data => { setItem(data) })
    }, [id])

    return (
        <main className={st.main}>
            <div className={st.contanier}>
                <div className={st.subTitle}>
                    <span onClick={() => navigate(CATALOG_ROUTE)} className={st.subTitleSpan} >каталог</span> /
                    <span onClick={() => navigate(CATALOG_ROUTE)} className={st.subTitleSpanType} >  {type}</span>
                </div>
                <div className={st.title}>{item.name}</div>
                <div className={st.bodyItem}>
                    <div className={st.contentImgs}>
                        <div className={st.imgMainBody}>
                            <div className={st.mainImg}><img src={mainPhoto ? process.env.REACT_APP_API_URL + '/' + mainPhoto : item.img1 && process.env.REACT_APP_API_URL + '/' + item.img1} alt="" /></div>
                        </div>
                        <div className={st.imgsList}>
                            <div
                                onClick={() => setMainPhoto(item.img1)}
                                className={mainPhoto === item.img1 ? st.mainImgItem + ' ' + st.mainImgItemActive : st.mainImgItem}>
                                <img
                                    src={item.img1 && process.env.REACT_APP_API_URL + '/' + item.img1} alt="" />
                            </div>
                            {item.img2 !== ''
                                &&
                                <div
                                    onClick={() => setMainPhoto(item.img2)}
                                    className={mainPhoto === item.img2 ? st.mainImgItem + ' ' + st.mainImgItemActive : st.mainImgItem}
                                >
                                    <img src={item.img2 && process.env.REACT_APP_API_URL + '/' + item.img2} alt="" />
                                </div>}
                            {item.img3 !== ''
                                &&
                                <div onClick={() => setMainPhoto(item.img3)}
                                    className={mainPhoto === item.img3 ? st.mainImgItem + ' ' + st.mainImgItemActive : st.mainImgItem}>
                                    <img src={item.img3 && process.env.REACT_APP_API_URL + '/' + item.img3} alt='' />
                                </div>}
                            {item.video &&
                                <div
                                    onClick={() => startVideo(item.video)}
                                    className={st.mainVIdeoItem}>
                                    <img src={play} alt="" />
                                </div>
                            }

                        </div>
                    </div>
                    <div className={st.description}>
                        <div className={st.titleDescript}>Характеристики</div>
                        <table className={st.table}>
                            <tbody>
                                {
                                    item.info.map((i, number) =>
                                        <tr className={number % 2 === 0 ? st.rowS : ''} key={i.id}><td>{i.title}</td><td>{i.description}</td></tr>
                                    )
                                }
                            </tbody>
                        </table>
                        {
                            item.price !== 0
                                ?
                                <div className={st.priceBlock}>
                                    <span className={st.priceN} >Цена</span>
                                    <span className={st.priceC} >от {item.price} руб/сутки</span>
                                </div>
                                :
                                <div className={st.priceBlock}>
                                    <span className={st.priceN} >Цена</span>
                                    <span
                                        onClick={(e) => {
                                            setModalShow(true)
                                        }}
                                        className={st.priceC + ' ' + st.button} >Уточнить в магазине</span>
                                </div>
                        }

                        <div className={st.buttonsBlock}>
                            <Button onClick={(e) => {
                                setModalShow(true);

                            }} classIcon='icon-telephone' variant='buttonBg' >Арендовать</Button>
                            <SocialLink icon='icon-telegram' variant='warning' to={process.env.REACT_APP_TELEGRAMM_LINK} />
                        </div>
                    </div>

                </div>
                <div className={st.fullDescript}>{item.description}</div>
                <BgItem style={{ top: '50%', right: '13' }} variant='car' />
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <MyVideodModal
                    src={mainVideo}
                    show={modalVideoShow}
                    onHide={() => setModalVIdeoShow(false)}
                />
            </div>
        </main>
    )
})
export default Item;

