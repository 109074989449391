
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { Context } from '../..'
import st from './Paginator.module.scss'

const Paginator = observer(() => {

    const { item } = useContext(Context)
    const pageCount = Math.ceil(item.totalCount / item.limit)
    const pages = []

    for (let i = 0; i < pageCount; i++) {
        pages.push(i + 1)
    }

    return (
        <div className={st.wrapper}>
            {pages.map(p => 
                <div key={p} onClick={() => item.setPage(p)} className={p === item.page ? st.pageItem + ' ' + st.pageItemActive : st.pageItem}><span>{p}</span></div>
                )}
        </div>

    )
})
export default Paginator;