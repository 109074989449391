import st from './Header.module.scss'
import logo from '../../assets/img/logo/logo.png'
import logoSm from '../../assets/img/logo/logoSm.png'
import Search from '../Search/Search';
import Menu from '../menu/Menu';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import BgItem from '../BgItem/BgItem';
import SocialLink from '../SocialLink/SocialLink';
import { MAIN_ROUTE } from '../../utils/constance';
import { useNavigate } from 'react-router-dom';

const Header = observer(() => {
    const navigate = useNavigate()
    const { user } = useContext(Context)
    const logOut = () => {
        localStorage.clear()
        user.setIsAuth(false)
        user.setUser({})
    }
    return (
        <header>
            <div className={st.header}>
                <BgItem style={{ top: '1%', right: '15%' }} />
                <div className={st.contanier}>
                    <div className={st.topLine}>
                        <div className={st.socialLink}>
                            <SocialLink to={process.env.REACT_APP_MAIL_LINK} icon='icon-youtube' />
                            <SocialLink to={process.env.REACT_APP_VK_LINK} icon='icon-vk' />
                            <SocialLink to={process.env.REACT_APP_TELEGRAMM_LINK} icon='icon-telegram' />
                        </div>
                        <div className={st.operating}>
                            <span>Режим работы с 8 : 00 до 18 : 00 <span className={st.wheitSpaan}>БЕЗ ПЕРЕРЫВА И ВЫХОДНЫХ</span> </span>
                            {
                                user.isAuth && <span onClick={logOut} className={`${st.iconAdmin} icon-login`}></span>
                            }
                        </div>
                    </div>
                    <div className={st.bottomine}>
                        <div onClick={() => navigate(MAIN_ROUTE)} className={st.logo}>
                            <img className={st.logoBig} src={logo} alt="" />
                            <img className={st.logoSm} src={logoSm} alt="" />
                        </div>
                        <div className={st.searchBlock}>
                            <Search />
                        </div>
                        <div className={st.adressBlock}>г. Саранск ул. Пролетарская дом 121</div>
                        <div className={st.phoneBlock}>
                            <span className='icon-telephone'></span>
                            <span className={st.phoneSpan} >{process.env.REACT_APP_PHONE}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={st.navMenu}>
                <div className={st.contanier}>
                    <Menu variant='header' />
                </div>
            </div>
        </header>
    )
})
export default Header;