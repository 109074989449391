import st from './Menu.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { menuLinks } from '../../routes';
import { useState } from 'react';
import logo from '../../assets/img/logo/logo.png'
import BgItem from '../BgItem/BgItem';
import Search from '../Search/Search';
import SocialLink from '../SocialLink/SocialLink';

const Menu = ({ variant }) => {
    const navigate = useNavigate()
    const location = useLocation().pathname
    const [openMenu, setOpenMenu] = useState(false)

    const pushMenu = () => {
        setOpenMenu(!openMenu)
    }
    const removeMenu = () => {
        setOpenMenu(false)
    }
    const clickMenu = (i) => {
        navigate(i.path)
        if (openMenu) {
            setOpenMenu(false)
        }
    }

    if (variant === 'header') {
        return (
            <div className={st.wrapper}>
                <div onClick={removeMenu} className={openMenu ? st.wrapperLinksActive + ' ' + st.wrapperLinks : st.wrapperLinks}>
                    <div onClick={e => e.stopPropagation()} className={st.menuList}>
                        <div className={st.topLine}>
                            <div className={st.logo}>
                                <img className={st.logoBig} src={logo} alt="" />
                                <div onClick={pushMenu} className={st.menuBurger}>
                                    <span className={openMenu ? st.elem + ' ' + st.elemActive : st.elem}></span>
                                </div>
                            </div>
                            <div className={st.bgElem}>
                                <Search closeMenu={() => setOpenMenu(false)} />
                                <BgItem style={{ top: '20%', left: '27%', width: '71%' }} />
                                <BgItem style={{ top: '54%', right: '26%', width: '100%' }} variant='car' />
                            </div>
                            <div className={st.menuLinksItem}>
                                {
                                    menuLinks.map(i =>
                                        <div className={st.listItemWrapper} onClick={() => clickMenu(i)} key={i.path}> <span className={i.path === location ? st.linkItem + ' ' + st.linkItemActive : st.linkItem}>{i.name}</span></div>
                                    )
                                }
                            </div>
                            <div className={st.socialLInks}>
                                <SocialLink to={process.env.REACT_APP_MAIL_LINK} variant='warning' icon='icon-youtube' />
                                <SocialLink to={process.env.REACT_APP_VK_LINK} variant='warning' icon='icon-vk' />
                                <SocialLink to={process.env.REACT_APP_TELEGRAMM_LINK} variant='warning' icon='icon-telegram' />
                            </div>
                        </div>
                        <div className={st.phoneBlockBurger}>
                            <span className='icon-telephone'></span>
                            <span className={st.phoneSpanBurger} >{process.env.REACT_APP_PHONE}</span>
                        </div>
                    </div>
                </div>
                <div className={st.phoneBlock}>
                    <span className='icon-telephone'></span>
                    <span className={st.phoneSpan} >{process.env.REACT_APP_PHONE}</span>
                </div>
                <div onClick={pushMenu} className={st.menuBurger}>
                    <span className={openMenu ? st.elem + ' ' + st.elemActive : st.elem}></span>
                </div>
            </div>
        )
    }
    return (
        <div className={st.wrapperFooter}>
            {
                menuLinks.map(i =>
                    <div className={st.listItemWrapper} onClick={() => navigate(i.path)} key={i.path}> <span className={i.path === location ? st.linkItemFooter + ' ' + st.linkItemActiveFooter : st.linkItemFooter}>{i.name}</span></div>
                )
            }
        </div>
    )
}
export default Menu;