// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RowTableFeedback_row__yyiXJ {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  padding: 5px;\n  color: black;\n}\n.RowTableFeedback_row__yyiXJ .RowTableFeedback_name__\\+9LbC {\n  flex: 0 0 100px;\n}\n.RowTableFeedback_row__yyiXJ .RowTableFeedback_rate__WJOnR {\n  flex: 0 0 50px;\n}\n.RowTableFeedback_row__yyiXJ .RowTableFeedback_feedback__m7La0 {\n  flex: 1 1 auto;\n}\n.RowTableFeedback_row__yyiXJ .RowTableFeedback_buttonsBlock__FRx4E {\n  display: flex;\n  gap: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/RowTableFeedback/RowTableFeedback.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AACI;EACI,eAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,cAAA;AACR;AAGI;EACI,aAAA;EACA,QAAA;AADR","sourcesContent":[".row {\n\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    padding: 5px;\n    color: black;\n    .name {\n        flex: 0 0 100px;\n    }\n    .rate {\n        flex: 0 0 50px;\n    }\n    .feedback {\n        flex: 1 1 auto;\n    }\n\n\n    .buttonsBlock {\n        display: flex;\n        gap: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "RowTableFeedback_row__yyiXJ",
	"name": "RowTableFeedback_name__+9LbC",
	"rate": "RowTableFeedback_rate__WJOnR",
	"feedback": "RowTableFeedback_feedback__m7La0",
	"buttonsBlock": "RowTableFeedback_buttonsBlock__FRx4E"
};
export default ___CSS_LOADER_EXPORT___;
