import CatalogItem from '../CatalogItem/CatalogItem';
import st from './CatalogList.module.scss'

const CatalogList = ({items, forma}) => {

    return (
        <div className={forma ? st.wrapper + ' ' + st.four : st.wrapper}>
            {items.map(i => <CatalogItem key={i.id} item={i}/>)} 
        </div>
    )
}
export default CatalogList;