import st from './Rating.module.scss'
import Rating from "react-rating";
import { observer } from 'mobx-react-lite';

const RatingStar = observer(({ rate, variant, setRate }) => {

    if (variant === 'feedbackForm') {
        return (
            <div className={st.rateWrapper}>
                <Rating
                    emptySymbol={`${st.noIconFeedForm} icon-star`}
                    fullSymbol={`${st.IconFeedForm} icon-star`}
                    initialRating={rate}
                    onClick={(rate) => {
                        setRate(rate)
                    }}
                />
            </div>
        )
    }
    return (
        <div onClick={e => e.stopPropagation()} className={st.rateWrapper}>
            {
                variant === 'feedback'
                    ?
                    <Rating
                        emptySymbol={`${st.noIconFeed} icon-star`}
                        fullSymbol={`${st.IconFeed} icon-star`}
                        initialRating={rate}
                    />
                    :
                    <Rating
                        emptySymbol={`${st.noIcon} icon-star`}
                        fullSymbol={`${st.activIcon} icon-star`}
                        initialRating={rate}
                        onClick={(rate) => {
                            alert('ваш отзыв принят')
                        }}
                    />
            }
        </div>
    )
})
export default RatingStar;