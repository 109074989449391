import { useEffect, useState } from 'react';
import st from './CatalogItem.module.scss'
import { useNavigate } from 'react-router-dom';
import { ITEM_ROUTE } from '../../utils/constance';
import { fetchInfo } from '../../http/itemApi'
import RatingStar from '../Rating/Rating';
import MyVerticallyCenteredModal from '../modals/ModalUpdateTool/ModalUpdateTool';

const CatalogItem = ({ item }) => {
    const navigate = useNavigate()
    const [info, setInfo] = useState([])
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        fetchInfo(item.id).then(data => setInfo(data))
    }, [item.id])

    return (
        <div className={st.wrapper}>
            <h4 onClick={() => navigate(ITEM_ROUTE + '/' + item.id)} className={st.title}>{item.name}</h4>
            <div onClick={() => navigate(ITEM_ROUTE + '/' + item.id)} className={st.imgBlock}>
                <img src={process.env.REACT_APP_API_URL + '/' + item.img1} alt="" />
            </div>
            <div className={st.reteBlock}>
                <div className={st.rateWrapper}>
                    <RatingStar rate={item.rate} />
                </div>

                <div onClick={(e) => {
                    setModalShow(true);
                }}
                    className={`${st.attButton} icon-basket`}></div>
            </div>
            <div className={st.descriptionBlock}>
                <table>
                    <tbody>
                        {
                            info && info.map(i =>
                                <tr key={i.id}><td>{i.title}</td><td>{i.description}</td></tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}
export default CatalogItem;