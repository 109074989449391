// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RowAdminTabel_wrapper1__2H4GM {\n  background: rgb(229, 229, 229);\n}\n\ntr {\n  display: flex;\n  align-items: center;\n}\ntr input {\n  background: inherit;\n  width: 100%;\n  height: 100%;\n}\ntr .RowAdminTabel_rowName__w1h\\+j {\n  flex: 0 0 120px;\n  color: #000;\n  font-weight: 600;\n}\ntr .RowAdminTabel_rowPrice__k5\\+O6 {\n  flex: 0 0 70px;\n}\ntr .RowAdminTabel_rowRate__Xv31q {\n  flex: 0 0 30px;\n}\ntr .RowAdminTabel_rowDescription__0-3mJ {\n  flex: 1 1 auto;\n}\ntr .RowAdminTabel_rowDescription__0-3mJ textarea {\n  width: 100%;\n  height: 100%;\n  background: inherit;\n}\ntr .RowAdminTabel_rowVideo__30tBV {\n  flex: 0 0 120px;\n}\ntr .RowAdminTabel_buttonsBlock__fuA9e {\n  display: flex;\n  gap: 5px;\n  flex: 0 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/components/RowAdminTabel/RowAdminTabel.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;AAEJ;AADI;EACI,mBAAA;EACA,WAAA;EACA,YAAA;AAGR;AADI;EACI,eAAA;EACA,WAAA;EACA,gBAAA;AAGR;AADI;EACI,cAAA;AAGR;AADI;EACI,cAAA;AAGR;AADI;EACI,cAAA;AAGR;AAFQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAIZ;AAAI;EACI,eAAA;AAER;AAAI;EACI,aAAA;EACA,QAAA;EACA,cAAA;AAER","sourcesContent":[".wrapper1 {\n    background: rgb(229, 229, 229);\n}\ntr {\n    display: flex;\n    align-items: center;\n    input {\n        background: inherit;\n        width: 100%;\n        height: 100%;\n    }\n    .rowName {\n        flex: 0 0 120px;\n        color: #000;\n        font-weight: 600;\n    }\n    .rowPrice {\n        flex: 0 0 70px\n    }\n    .rowRate {\n        flex: 0 0 30px\n    }\n    .rowDescription {\n        flex: 1 1 auto;\n        textarea {\n            width: 100%;\n            height: 100%;\n            background: inherit;\n        }\n\n    }\n    .rowVideo {\n        flex: 0 0 120px;\n    }\n    .buttonsBlock {\n        display: flex;\n        gap: 5px;\n        flex: 0 0 auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper1": "RowAdminTabel_wrapper1__2H4GM",
	"rowName": "RowAdminTabel_rowName__w1h+j",
	"rowPrice": "RowAdminTabel_rowPrice__k5+O6",
	"rowRate": "RowAdminTabel_rowRate__Xv31q",
	"rowDescription": "RowAdminTabel_rowDescription__0-3mJ",
	"rowVideo": "RowAdminTabel_rowVideo__30tBV",
	"buttonsBlock": "RowAdminTabel_buttonsBlock__fuA9e"
};
export default ___CSS_LOADER_EXPORT___;
