import { Route, Routes } from 'react-router-dom';
import Catalog from '../../page/Catalog/Catalog';
import { authRouts, pablicRouts } from '../../routes';
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const AppRouter = observer(() => {
  const { user } = useContext(Context);
  return (
    <Routes>
      {pablicRouts.map((r) => (
        <Route key={r.path} path={r.path} element={<r.Component />} exact />
      ))}
      {user.isAuth &&
        authRouts.map((r) => <Route key={r.path} path={r.path} element={<r.Component />} exact />)}
      <Route path="*" element={<Catalog />} exact />
    </Routes>
  );
});
export default AppRouter;
