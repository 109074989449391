import { $authHost, $host } from "./index";

//работа с типами
export const createType = async (type) => {
    const {data} = await $authHost.post('api/type', {name: type})
    return data
}
export const fetchTypes = async () => {
    const {data} = await $host.get('api/type')
    return data
}
export const fetchOneType = async (id) => {
    const {data} = await $host.get(`api/type/${id}`)
    return data
}
export const dropType = async (id) => {
    const {data} = await $authHost.delete('api/type', id)
    return data
}

// работа с продуктами
export const createItem = async (name) => {
    const {data} = await $authHost.post('api/tools', name)
    return data
}
export const fetchAllItem = async (typeId, page, limit) => {
    const {data} = await $host.get('api/tools', {params: {typeId, page, limit}})
    return data
}
export const fetchAllSearch = async (text) => {
    const {data} = await $host.get('api/tools/search', {params: {text}})
    return data
}
export const fetchOneItem = async (id) => {
    const {data} = await $host.get(`api/tools/${id}`)
    return data
}
export const dropItem = async (id) => {
    const {data} = await $authHost.delete(`api/tools/${id}`)
    return data
}

export const updateItem = async (id, price, rate, description, video) => {
    const {data} = await $authHost.put('api/tools', {id, price, rate, description, video})
    return data
}
// работа с  таблицей информации
export const fetchInfo = async (toolId) => {
    const {data} = await $host.get('api/info', {params: {toolId}})
    return data
}
