import BgItem from '../../components/BgItem/BgItem';
import CardMap from '../../components/Map/Map';
import st from './Contacts.module.scss'

const Contacts = () => {
    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <div className={st.contactsWrapper}>
                    <div className={st.title}>Как к нам проехать</div>
                    <div className={st.adress}>МЫ НАХОДИМСЯ В гОРОДЕ СаранскЕ НА ул. Пролетарская дом 121</div>
                    <div className={st.work}>Режим работы с 8:00 до 18:00<br></br>БЕЗ ПЕРЕРЫВА И ВЫХОДНЫХ !!! </div>
                    <div className={st.questions}>Если остались вопросы вы можете связаться с нами по телефонам :</div>
                    <div className={st.number}>{process.env.REACT_APP_PHONE2}</div>
                    <div className={st.number2}>{process.env.REACT_APP_PHONE}</div>
                    <div className={st.textEmail}>или по электронной почте </div>
                    <div className={st.email}>{process.env.REACT_APP_MAIL}</div>
                </div>
                <div className={st.contactsCard}>
                    <div className={st.titleCard}>Как к нам проехать</div>
                    <div className={st.card}>
                        <CardMap />
                    </div>
                </div>
                <BgItem width='50%' style={{ top: '60%', left: '0%' }} />
                <BgItem style={{ top: '-10%', right: '0%' }} variant='car' />
            </div>
        </div>
    )
}
export default Contacts;