import { Modal } from "react-bootstrap";
import st from './MyVideodModal.module.scss'

function MyVideodModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered

    >
      <Modal.Body className={st.wrapper}>
        <div onClick={props.onHide} className={st.close}>X</div>
        <iframe className={st.video}
          title='Youtube player'
          sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
          src={`https://youtube.com/embed/${props.src}?autoplay=1`}>
        </iframe>
      </Modal.Body>
    </Modal>
  );
}

export default MyVideodModal;

