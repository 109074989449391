import st from './Admin.module.scss'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { createItem, createType, fetchAllItem, fetchTypes } from '../../http/itemApi'
import { observer } from 'mobx-react-lite'
import RowAdminTabel from '../../components/RowAdminTabel/RowAdminTabel'
import { fetchAllFeedbackNoCheck } from '../../http/feedbackApi'
import RowTableFeedback from '../../components/RowTableFeedback/RowTableFeedback'

const Admin = observer(() => {
    const { item, feedback } = useContext(Context)

    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [rate, setRate] = useState(0)
    const [info, setInfo] = useState([])
    const [file1, setFile1] = useState(null)
    const [file2, setFile2] = useState(null)
    const [file3, setFile3] = useState(null)
    const [videoLink, setVideoLink] = useState('')
    const [description, setDescription] = useState('')

    const [addListUpdate, setAddListUpdate] = useState(false)

    const addTools = () => {
        setInfo([...info, { title: '', description: '', number: Date.now() }])
    }
    const dropInfo = num => {
        setInfo(info.filter(n => n.number !== num))
    }
    const changeInfo = (key, value, number) => {
        setInfo(info.map(i => i.number === number ? { ...i, [key]: value } : i))
    }
    const addType = () => {
        createType(type).then(data => alert(`Тип "${data.name}" успешно добавлен`))
    }
    const addItem = () => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('price', `${price}`)
        formData.append('rate', `${rate}`)
        formData.append('img1', file1)
        formData.append('img2', file2)
        formData.append('img3', file3)
        formData.append('typeId', item.selectedType.id)
        formData.append('description', description)
        formData.append('video', videoLink)
        formData.append('info', JSON.stringify(info))
        createItem(formData).then(data => alert(`Инструмент ${data.name} успешно добавлен! Просмотреть по ссылке http://localhost:3000/product/${data.id}`))
        // console.log(formData)
    }
    useEffect(() => {
        fetchTypes().then(data => item.setTypes(data))
    }, [item])

    const updateList = (type) => {
        item.setSelectedType(type)
        fetchAllItem(type.id, 1, 20).then(data => {
            item.setItems(data.rows)
            item.setTotalCount(data.count)
        })
        setAddListUpdate(true)
    }
    const lookFeedback = () => {
        fetchAllFeedbackNoCheck(1, 10).then(data => feedback.setCheckedFeedbackNoChecked(data.rows))
    }

    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <div className={st.addWrapper}>
                    <div className={st.title}>Добавить ТИП:</div>
                    <Form  >
                        <Form.Control value={type} onChange={e => setType(e.target.value)} className='mb-2' variant='warning' size='sm' placeholder='Введите название типа' />
                        <Button onClick={() => addType(type)} variant='warning' size='sm' >Добавить тип</Button>
                    </Form>
                </div>
                <div className={st.addWrapper}>
                    <div className={st.title}>Добавить тнструмент:</div>
                    <Form>
                        <Dropdown className='mb-2'>
                            <Dropdown.Toggle variant='warning' size='sm' >{item.selectedType.name || 'Выберете тип'}</Dropdown.Toggle>
                            <Dropdown.Menu >
                                {item.types.map(i =>
                                    <Dropdown.Item onClick={() => item.setSelectedType(i)} key={i.id}>{i.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control onChange={e => setName(e.target.value)} value={name} className='mb-2' variant='warning' size='sm' placeholder='Введите название техники' />
                        <Form.Control onChange={e => setPrice(e.target.value)} value={price !== 0 ? price : ''} type='number' className='mb-2' variant='warning' size='sm' placeholder='Введите начальную цену' />
                        <Form.Control onChange={e => setRate(e.target.value)} value={rate !== 0 ? rate : ''} type='number' className='mb-2' variant='warning' size='sm' placeholder='Введите начальный рейтинг' />
                        <Form.Control onChange={e => setVideoLink(e.target.value)} value={videoLink} className='mb-2' variant='warning' size='sm' placeholder='Добавьте ссылку на видео' />
                        <Form.Control onChange={e => setFile1(e.target.files[0])} type='file' className='mb-2' variant='warning' size='sm' />
                        <Form.Control onChange={e => setFile2(e.target.files[0])} type='file' className='mb-2' variant='warning' size='sm' />
                        <Form.Control onChange={e => setFile3(e.target.files[0])} type='file' className='mb-2' variant='warning' size='sm' />
                        <Form.Control
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                            size='sm'
                            as="textarea"
                            placeholder="Введите краткое описание техники"
                            style={{ height: '100px' }}
                            className='mb-2'
                        />
                        <hr />
                        <Button className='mb-2' onClick={addTools} variant='outline-warning' size='sm'>Добавить описание</Button>
                        {
                            info.map(i =>
                                <Row className='mb-2' key={i.number}>
                                    <Col md={5} ><Form.Control onChange={e => changeInfo('title', e.target.value, i.number)} value={i.title} size='sm' placeholder='Введите названия характеристики'></Form.Control></Col>
                                    <Col md={5} ><Form.Control onChange={e => changeInfo('description', e.target.value, i.number)} value={i.description} size='sm' placeholder='Введите описание характеристики' ></Form.Control></Col>
                                    <Col md={2} ><Button onClick={() => dropInfo(i.number)} size='sm' variant='outline-danger' >Del</Button></Col>
                                </Row>
                            )
                        }
                        <hr />
                        <Button onClick={addItem} variant='warning' size='sm'>Добавить инструмент</Button>
                    </Form>
                </div>
                <div className={st.addWrapper}>
                    <div className={st.title}>Редактировать инструменты:</div>
                    <div className={st.buttonsBlockUpdate}>
                        <Dropdown >
                            <Dropdown.Toggle variant='warning' size='sm' >{item.selectedType.name || 'Выберете тип'}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {item.types.map(i =>
                                    <Dropdown.Item onClick={() => updateList(i)} key={i.id}>{i.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button onClick={() => setAddListUpdate(false)} variant='warning' size='sm'>Скрыть список</Button>
                    </div>
                    <table>
                        <tbody>
                            {
                                addListUpdate && item.items.map((i, index) =>
                                    <RowAdminTabel key={i.id} i={i} index={index} />
                                )
                            }
                        </tbody>
                    </table>

                </div>
                <div className={st.addWrapper}>
                    <div className={st.title}>Редактировать отзывы:</div>
                    <Button onClick={lookFeedback} variant='warning' size='sm'>Показать список</Button>
                    <div className={st.buttonsBlockUpdate}>

                        <table>
                            <tbody>
                                {
                                    feedback.checkedFeedbackNoChecked.map(f =>
                                        <RowTableFeedback key={f.id} feed={f} />
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default Admin;