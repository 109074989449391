// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rating_rateWrapper__9UCjh .Rating_noIcon__b8IIK {\n  color: #E8E8E8;\n}\n.Rating_rateWrapper__9UCjh .Rating_activIcon__U\\+gtM {\n  color: #FFC123;\n}\n.Rating_rateWrapper__9UCjh .Rating_noIconFeed__A7Uff {\n  margin: 5px;\n  font-size: 18px;\n  color: #E8E8E8;\n}\n.Rating_rateWrapper__9UCjh .Rating_IconFeed__f6zx8 {\n  margin: 5px;\n  font-size: 18px;\n  color: #FFC123;\n}\n.Rating_rateWrapper__9UCjh .Rating_noIconFeedForm__hOOqB {\n  margin: 5px;\n  font-size: 20px;\n  color: #ffffff;\n}\n.Rating_rateWrapper__9UCjh .Rating_IconFeedForm__gOczq {\n  margin: 5px;\n  font-size: 20px;\n  color: #FFC123;\n}", "",{"version":3,"sources":["webpack://./src/components/Rating/Rating.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAEI;EACI,cAAA;AAAR;AAEI;EACI,WAAA;EACA,eAAA;EACA,cAAA;AAAR;AAGI;EACI,WAAA;EACA,eAAA;EACA,cAAA;AADR;AAGI;EACI,WAAA;EACA,eAAA;EACA,cAAA;AADR;AAGI;EACI,WAAA;EACA,eAAA;EACA,cAAA;AADR","sourcesContent":[".rateWrapper {\n    .noIcon {\n        color: #E8E8E8;\n    }\n    .activIcon {\n        color: #FFC123;\n    }\n    .noIconFeed {\n        margin: 5px;\n        font-size: 18px;\n        color: #E8E8E8;\n    }\n\n    .IconFeed {\n        margin: 5px;\n        font-size: 18px;\n        color: #FFC123;\n    }\n    .noIconFeedForm {\n        margin: 5px;\n        font-size: 20px;\n        color: #ffffff;\n    }\n    .IconFeedForm {\n        margin: 5px;\n        font-size: 20px;\n        color: #FFC123;\n    }\n\n    // label {\n    //     color: #E8E8E8;\n    //     font-size: 20px;\n    //     cursor: pointer;\n    // }\n    // label:hover {\n    //     color: #FFC123;\n    // }\n\n    // label:hover~label::before {\n    //     color: #FFC123;\n    // }\n    // input {\n\n    // }\n    // input:checked + label::before,\n    // input:checked ~ label::before {\n    //     color: #FFC123;\n    // }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rateWrapper": "Rating_rateWrapper__9UCjh",
	"noIcon": "Rating_noIcon__b8IIK",
	"activIcon": "Rating_activIcon__U+gtM",
	"noIconFeed": "Rating_noIconFeed__A7Uff",
	"IconFeed": "Rating_IconFeed__f6zx8",
	"noIconFeedForm": "Rating_noIconFeedForm__hOOqB",
	"IconFeedForm": "Rating_IconFeedForm__gOczq"
};
export default ___CSS_LOADER_EXPORT___;
