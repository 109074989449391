import React from 'react';
import s from './Slide.module.scss';

function SlideComponent({ img }) {
  return (
    <div className={s.wrapper}>
      <img src={img} alt="" />
    </div>
  );
}

export default SlideComponent;
