import About from "./page/About/About"
import Admin from "./page/Admin/Admin"
import Auth from "./page/Auth/Auth"
import Catalog from "./page/Catalog/Catalog"
import Contacts from "./page/Contacts/Contacts"
import Item from "./page/Item/Item"
import Main from "./page/Main/Main"
import SearchPage from "./page/SearchPage/SearchPage"
import { ABOUT_ROUTE, ADMIN_ROUTE, CATALOG_ROUTE, CONTACTS_ROUTE, ITEM_ROUTE, LOGIN_ROUTE, MAIN_ROUTE, SEARCH_ROUTE } from "./utils/constance"


export const pablicRouts = [
    {
        path: MAIN_ROUTE,
        Component: Main
    },
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: CATALOG_ROUTE,
        Component: Catalog
    },
    {
        path: ITEM_ROUTE + '/:id',
        Component: Item
    },
    {
        path: CONTACTS_ROUTE,
        Component: Contacts
    },
    {
        path: ABOUT_ROUTE,
        Component: About
    },
    {
        path: SEARCH_ROUTE,
        Component: SearchPage
    },
]


export const authRouts = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
]


export const menuLinks = [
    {
        name: 'Главная',
        path: MAIN_ROUTE
    },
    {
        name: 'Каталог',
        path: CATALOG_ROUTE
    },
    {
        name: 'О магазине',
        path: ABOUT_ROUTE
    },
    {
        name: 'Контакты',
        path: CONTACTS_ROUTE
    },
]