import Button from '../Button/Button';
import st from './Gallery.module.scss'
import row from '../../assets/img/photos/row.jpeg'
import row2 from '../../assets/img/photos/row2.jpeg'
import row3 from '../../assets/img/photos/row3.jpeg'
import row4 from '../../assets/img/photos/row4.jpeg'
import row5 from '../../assets/img/photos/row5.jpeg'
import row6 from '../../assets/img/photos/row6.jpeg'
import row7 from '../../assets/img/photos/row7.jpeg'
import row8 from '../../assets/img/photos/row8.jpeg'

import ews1 from '../../assets/img/photos/ews1.jpeg'
import ews2 from '../../assets/img/photos/ews2.jpeg'
import ews3 from '../../assets/img/photos/ews3.jpeg'
import ews4 from '../../assets/img/photos/ews4.jpeg'
import ews5 from '../../assets/img/photos/ews5.jpeg'
import ews6 from '../../assets/img/photos/ews6.jpeg'
import ews7 from '../../assets/img/photos/ews7.jpeg'
import ews8 from '../../assets/img/photos/ews8.jpeg'
import { useNavigate } from 'react-router-dom';
import { CATALOG_ROUTE } from '../../utils/constance';
import { useState } from 'react';
import MyVerticallyCenteredModal from '../modals/ModalUpdateTool/ModalUpdateTool';



const Gallery = ({ variant }) => {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate()

    if (variant === 'right') {
        return (
            <div className={st.body}>
                <div className={st.column + ' ' + st.columnNone}>
                    <div className={st.block1}><img src={row3} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block2}><img src={row2} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block3}><img src={row8} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column}>
                    <div className={st.block2}><img src={row4} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block3}><img src={row} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block1}><img src={row6} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column}>
                    <div className={st.text3}>
                        <div className={st.textBody}>
                            <div className={st.text}>Если нужна техника, а вы заняты. Просто позвоните нам, мы быстро доставим инструменты. Для вас постоянно работает служба доставки</div>
                            <Button onClick={() => setModalShow(true)} variant='buttonBg' classIcon='icon-basket' >Заказать инструмент</Button>
                        </div>
                    </div>
                    <div className={st.block1}><img src={row5} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block2}><img src={row7} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        )
    }
    if (variant === 'centr') {
        return (
            <div className={st.body}>
                <div className={st.column}>
                    <div className={st.block1}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block2}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block3}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column}>
                    <div className={st.block2}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.text3}>
                        <div className={st.textBody}>
                            <div className={st.text}>Нашему магазину уже более 2х лет, за это время многие компании и люди стали нашими клиентами</div>
                            <Button variant='buttonBg' classIcon='icon-basket' >К товарам</Button>
                        </div>
                    </div>
                    <div className={st.block1}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column + ' ' + st.columnNone}>
                    <div className={st.block3}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block1}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block2}><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
            </div>

        )
    }
    if (variant === 'left') {
        return (
            <div className={st.body}>
                <div className={st.column}>
                    <div className={st.text1}>
                        <div className={st.textBody}>
                            <div className={st.text}>Нашему магазину уже более 2х лет, за это время многие компании и люди стали нашими постоянными клиентами</div>
                            <Button onClick={() => setModalShow(true)} variant='buttonBg' classIcon='icon-basket' >Заказать инструмент</Button>
                        </div>
                    </div>
                    <div className={st.block2}><img src={ews1} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block3}><img src={ews4} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column}>
                    <div className={st.block2}><img src={ews5} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block3}><img src={ews6} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block1}><img src={ews7} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <div className={st.column + ' ' + st.columnNone}>
                    <div className={st.block3}><img src={ews3} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block1}><img src={ews8} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                    <div className={st.block2}><img src={ews2} alt="" /><div className={st.bg}></div><div className={st.button}><Button onClick={() => navigate(CATALOG_ROUTE)} variant='buttonShwarc'>Выбрать</Button></div></div>
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        )
    }

}
export default Gallery;