// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Slide_wrapper__xvq5S {\n  position: relative;\n  width: 100%;\n  height: 300px;\n  background: rgb(182, 182, 182);\n}\n.Slide_wrapper__xvq5S img {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://./src/components/SliderSwiperComponent/Slide.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACR","sourcesContent":[".wrapper {\n    position: relative;\n    width: 100%;\n    height: 300px;\n    background: rgb(182, 182, 182);\n\n    img {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Slide_wrapper__xvq5S"
};
export default ___CSS_LOADER_EXPORT___;
