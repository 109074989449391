// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y, Keyboard } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import img1 from '../../assets/img/slider/img1.jpeg';
import img2 from '../../assets/img/slider/img2.jpeg';
import img3 from '../../assets/img/slider/img3.jpeg';
import img4 from '../../assets/img/slider/img4.jpeg';
import img5 from '../../assets/img/slider/img5.jpeg';
import img6 from '../../assets/img/slider/img6.jpeg';
import img7 from '../../assets/img/slider/img7.jpeg';
import img8 from '../../assets/img/slider/img8.jpeg';
import img9 from '../../assets/img/slider/img9.jpeg';
import img10 from '../../assets/img/slider/img10.jpeg';
import img11 from '../../assets/img/slider/img11.jpeg';
import img12 from '../../assets/img/slider/img12.jpeg';
import img13 from '../../assets/img/slider/img13.jpeg';
import img14 from '../../assets/img/slider/img14.jpeg';
import img15 from '../../assets/img/slider/img15.jpeg';
import img16 from '../../assets/img/slider/img16.jpeg';
import SlideComponent from './SlideComponent';

function SliderSwiperComponent() {
  const pageWidth = document.documentElement.scrollWidth;
  const sliderMainCount = () => {
    if (pageWidth < 590) {
      return 1;
    }
    if (pageWidth < 800) {
      return 2;
    }
    return 3;
  };
  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y, Keyboard]}
      loop
      keyboard
      spaceBetween={10}
      slidesPerView={sliderMainCount()}
      navigation
      scrollbar={{ draggable: true }}>
      <SwiperSlide>
        <SlideComponent img={img1} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img2} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img3} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img4} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img5} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img6} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img7} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img8} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img9} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img10} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img11} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img12} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img13} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img14} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img15} />
      </SwiperSlide>
      <SwiperSlide>
        <SlideComponent img={img16} />
      </SwiperSlide>
      ...
    </Swiper>
  );
}

export default SliderSwiperComponent;
