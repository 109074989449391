
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { login } from '../../http/userApi';
import Button from '../../components/Button/Button';
import { ADMIN_ROUTE } from '../../utils/constance';
import st from './Auth.module.scss'

const Auth = observer(() => {
    const { user } = useContext(Context)
    const [loginUser, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const log = async () => {
        try {
            const data = await login(loginUser, password)
            user.setUser(data)
            user.setIsAuth(true)
            navigate(ADMIN_ROUTE)

        } catch(e){
            alert(e.response.data.message)
        }
    }


    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <div className={st.form}>
                    <div className={st.inputWrapper}>
                        <input value={loginUser} onChange={e => setLogin(e.target.value)} type="text" placeholder='введите логин' />
                    </div>
                    <div className={st.inputWrapper}>
                        <input value={password} onChange={e => setPassword(e.target.value)} type="password" placeholder='введите пароль' />
                    </div>
                    <Button onClick={log} variant='buttonBg' classIcon='icon-login' >Войти</Button>
                </div>
            </div>
        </div>
    )
})
export default Auth;