import { useContext, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Context } from '.';
import AppRouter from './components/AppRouter/AppRouter';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { check } from './http/userApi';

function App() {
  const { user } = useContext(Context);
  useEffect(() => {
    if (localStorage.length > 0) {
      check().then((data) => {
        user.setUser(data);
        user.setIsAuth(true);
      });
    }
  }, [user]);
  return (
    <BrowserRouter>
      <Header />
      <AppRouter />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
