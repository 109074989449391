import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Gallery from '../../components/Gallery/Gallery';
import MyVerticallyCenteredModal from '../../components/modals/ModalUpdateTool/ModalUpdateTool';
import SliderSwiperComponent from '../../components/SliderSwiperComponent/SliderSwiperComponent';
import SocialLink from '../../components/SocialLink/SocialLink';
import { CATALOG_ROUTE } from '../../utils/constance';
import st from './Main.module.scss'

const Main = () => {
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(false);

    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <main className={st.mainBlock}>
                    <div className={st.contentBlock}>
                        <div className={st.headers}>
                            <h1>Прокат инструмента в Саранске</h1>
                            <h2>широким ассортиментом качественных и профессиональных инструментов</h2>
                        </div>
                        <Button onClick={() => navigate(CATALOG_ROUTE)} classIcon='icon-basket' variant='buttonBg'>Перейти в каталог</Button>
                    </div>
                    <div className={st.socialBlock}>
                        <SocialLink to={process.env.REACT_APP_MAIL_LINK} variant='warning' icon='icon-youtube' />
                        <SocialLink to={process.env.REACT_APP_VK_LINK} variant='warning' icon='icon-vk' />
                        <SocialLink to={process.env.REACT_APP_TELEGRAMM_LINK} variant='warning' icon='icon-telegram' />
                    </div>
                </main>
                <div className={st.galery}>
                    <div className={st.title}>Наши инструменты</div>
                    <SliderSwiperComponent />
                </div>
                <div className={st.galery}>
                    <div className={st.title}>Галерея</div>
                    <Gallery variant='right' />
                </div>
                <div className={st.galery}>
                    <div className={st.title}>Наша техника</div>
                    <Gallery variant='left' />
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}
export default Main;