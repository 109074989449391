import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { createFeedback } from '../../http/feedbackApi';
import Button from '../Button/Button';
import RatingStar from '../Rating/Rating';
import st from './FeedbackForm.module.scss';

const FeedbackForm = observer(() => {
  const [name, setName] = useState('');
  const [rate, setRate] = useState(0);
  const [feedback, setFeedback] = useState('');

  const sendFeedback = () => {
    if (name === '' || feedback === '' || rate === 0) {
      return alert('заполните все поля');
    }
    createFeedback(name, rate, feedback).then((data) => alert('Отзыв отправлен на модерацию'));
    setName('');
    setRate(0);
    setFeedback('');
  };

  return (
    <div className={st.feedbackBlock}>
      <div className={st.titleForm}>Оставьте отзыв о наших услугах</div>
      <form className={st.form}>
        <div className={st.wrapperInput}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите ваше имя"
            type="text"
          />
        </div>

        <div className={st.wrapperStars}>
          <RatingStar variant="feedbackForm" rate={rate} setRate={setRate} />
          <span className={st.wrapperStarsSpan}> Поставьте оценку </span>
        </div>
        <div className={st.wrapperInput}>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className={st.textArea}
            placeholder="Что вы о нас думаете?))"
          />
        </div>
      </form>
      <Button onClick={sendFeedback} variant="buttonBg">
        Опубликовать
      </Button>
    </div>
  );
});

export default FeedbackForm;
