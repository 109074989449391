// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BgItem_svgClass__ZLpM1 {\n  visibility: visible;\n  position: absolute;\n  z-index: 0;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://./src/components/BgItem/BgItem.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;AACJ","sourcesContent":[".svgClass {\n    visibility: visible;\n    position: absolute;\n    z-index: 0;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgClass": "BgItem_svgClass__ZLpM1"
};
export default ___CSS_LOADER_EXPORT___;
