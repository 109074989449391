import { makeAutoObservable } from "mobx"

export class FeedbackStore {
    constructor() {
        this._checkedFeedback = []
        this._checkedFeedbackNoChecked = []

        makeAutoObservable(this)
    }

    setCheckedFeedback(feedbacks){
        this._checkedFeedback = feedbacks
    }
    setCheckedFeedbackNoChecked(feedbacks){
        this._checkedFeedbackNoChecked = feedbacks
    }

    get checkedFeedback(){
        return this._checkedFeedback
    }
    get checkedFeedbackNoChecked(){
        return this._checkedFeedbackNoChecked
    }
}