import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Context } from '../..';
import { dropItem, fetchAllItem, updateItem } from '../../http/itemApi';
import st from './RowAdminTabel.module.scss'

const RowAdminTabel = observer(({i, index,}) => {
    const {item} = useContext(Context)
    const [price, setPrice] = useState(i.price)
    const [rate, setRate] = useState(i.rate)
    const [description, setDescription] = useState(i.description)
    const [video, setVideo] = useState(i.video)

    const updateTool = () => {
        updateItem(i.id, price, rate, description, video).then(data => alert('Изменения сохранены'))
    }
    const deleteTool = () => {
        dropItem(i.id)
        .then(data => fetchAllItem(i.typeId, 1, 20)
        .then(data => {
            item.setItems(data.rows)
            item.setTotalCount(data.count)
            alert('инструмент упешно удален')
        }))
    }
    return (
        <tr className={index % 2 === 0 ? st.wrapper1 : st.wrapper2} >
            <td className={st.rowName}>{i.name}</td>
            <td className={st.rowPrice}> <input value={price} onChange={e => setPrice(e.target.value)} type="text" /></td>
            <td className={st.rowRate}> <input value={rate} onChange={e => setRate(e.target.value)} type="text" /></td>
            <td className={st.rowDescription} > <textarea value={description} onChange={e => setDescription(e.target.value)} type="text" /> </td>
            <td className={st.rowVideo}> <input value={video} onChange={e => setVideo(e.target.value)} type="text" /></td>
            <td className={st.buttonsBlock}>
                <Button onClick={updateTool} size='sm' variant='warning' >Сохранить</Button>
                <Button onClick={deleteTool} size='sm' variant='danger' >Удалить</Button>
            </td>
        </tr>

    )
})
export default RowAdminTabel;