import { makeAutoObservable } from "mobx"

export class ItemStore {
    constructor() {
        this._items = []
        this._types = []
        this._searchList = []

        this._selectedType = {}

        this._page = 1
        this._totalCount = 0
        this._limit = 9
        makeAutoObservable(this)
    }
    setItems(item) {
        this._items = item
    }
    setTypes(type) {
        this._types = type
    }
    setSelectedType(type) {
        this.setPage(1)
        this._selectedType = type
    }
    setSearchList(tools){
        this._searchList = tools
    }



    setPage(page) {
        this._page = page
    }
    setTotalCount(count) {
        this._totalCount = count
    }
    setLimit(limit) {
        this._limit = limit
    }


    get items() {
        return this._items
    }
    get types() {
        return this._types
    }
    get searchList(){
        return this._searchList
    }

    get selectedType() {
        return this._selectedType
    }



    get page() {
        return this._page
    }
    get totalCount() {
        return this._totalCount
    }
    get limit() {
        return this._limit
    }
}