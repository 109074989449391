// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeedbackItem_wrapper__XynxQ {\n  position: relative;\n  z-index: 2;\n  width: 100%;\n  background: #FFFFFF;\n  border: 3px solid #FFC123;\n  border-radius: 8px;\n  padding: 42px 38px;\n}\n.FeedbackItem_wrapper__XynxQ .FeedbackItem_name__lFx9u {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 122.2%;\n  letter-spacing: 0.045em;\n  color: #2b2b2b;\n  margin-bottom: 20px;\n}\n.FeedbackItem_wrapper__XynxQ .FeedbackItem_rate__Ine4- {\n  margin-bottom: 8px;\n}\n.FeedbackItem_wrapper__XynxQ .FeedbackItem_feedback__ymCmI {\n  font-weight: 300;\n  font-size: 14px;\n  line-height: 192.2%;\n  letter-spacing: 0.045em;\n  color: #595959;\n}", "",{"version":3,"sources":["webpack://./src/components/FeedbackItem/FeedbackItem.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAAI;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;AAER;AAAI;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAER","sourcesContent":[".wrapper {\n    position: relative;\n    z-index: 2;\n    width: 100%;\n    background: #FFFFFF;\n    border: 3px solid #FFC123;\n    border-radius: 8px;\n    padding: 42px 38px;\n    .name {\n        font-weight: 500;\n        font-size: 16px;\n        line-height: 122.2%;\n        letter-spacing: 0.045em;\n        color: #2b2b2b;\n        margin-bottom: 20px;\n    }\n    .rate {\n        margin-bottom: 8px;\n    }\n    .feedback {\n        font-weight: 300;\n        font-size: 14px;\n        line-height: 192.2%;\n        letter-spacing: 0.045em;\n        color: #595959;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FeedbackItem_wrapper__XynxQ",
	"name": "FeedbackItem_name__lFx9u",
	"rate": "FeedbackItem_rate__Ine4-",
	"feedback": "FeedbackItem_feedback__ymCmI"
};
export default ___CSS_LOADER_EXPORT___;
