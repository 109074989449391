import { $authHost, $host } from "./index";

//работа с отзывами
export const createFeedback = async (name, rate, feedback) => {
    const {data} = await $host.post('api/feedback', {name, rate, feedback})
    return data
}
export const fetchAllFeedbackCheck = async ( page, limit) => {
    const {data} = await $host.get('api/feedback', {params: {check: true, page, limit}})
    return data
}
export const fetchAllFeedbackNoCheck = async ( page, limit) => {
    const {data} = await $authHost.get('api/feedback', {params: {check: false, page, limit}})
    return data
}
export const updateFeedback = async (id, check) => {
        const {data} = await $authHost.put('api/feedback', {id, check})
        return data
    }
export const dropFeedback = async (id) => {
    const {data} = await $authHost.delete(`api/feedback/${id}`)
    return data
}
// export const fetchTypes = async () => {
//     const {data} = await $host.get('api/type')
//     return data
// }
// export const fetchOneType = async (id) => {
//     const {data} = await $host.get(`api/type/${id}`)
//     return data
// }
// export const dropType = async (id) => {
//     const {data} = await $authHost.delete('api/type', id)
//     return data
// }

// // работа с продуктами
// export const createItem = async (name) => {
//     const {data} = await $authHost.post('api/tools', name)
//     return data
// }

// export const fetchOneItem = async (id) => {
//     const {data} = await $host.get(`api/tools/${id}`)
//     return data
// }
// export const dropItem = async (id) => {
//     const {data} = await $authHost.delete('api/tools', id)
//     return data
// }

// 
// // работа с  таблицей информации
// export const fetchInfo = async (toolId) => {
//     const {data} = await $host.get('api/info', {params: {toolId}})
//     return data

