import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { fetchAllSearch } from '../../http/itemApi';
import { SEARCH_ROUTE } from '../../utils/constance';
import st from './Search.module.scss'
const Search = observer(({ closeMenu }) => {
    const { item } = useContext(Context)
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const searchButton = () => {
        if (search.length < 3) {
            alert('минимум 3 символа')
            return
        }
        fetchAllSearch(search)
            .then(data => item.setSearchList(data.rows))
            .finally(navigate(SEARCH_ROUTE))
        setSearch('')
        if (closeMenu) closeMenu()
    }
    const keyPress = event => {
        if (event.key === 'Enter') {
            fetchAllSearch(search)
                .then(data => item.setSearchList(data.rows))
                .finally(navigate(SEARCH_ROUTE))
            setSearch('')
        }
    }
    return (
        <div className={st.wrapper}>
            <input
                onKeyPress={keyPress}
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder='введите название мин 3 символа'
                type="text" />
            <div onClick={searchButton} className={st.searchButton}>
                <div className={`${st.icon} icon-search`}></div>
            </div>
        </div>
    )
})
export default Search;