// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyVideodModal_wrapper__WtvNQ {\n  position: relative;\n  background: rgb(70, 70, 70);\n  height: 450px;\n  padding: 0;\n}\n.MyVideodModal_wrapper__WtvNQ .MyVideodModal_close__5s8im {\n  position: absolute;\n  cursor: pointer;\n  display: inline-block;\n  padding: 5px 10px;\n  border-radius: 3px;\n  top: 10px;\n  right: 10px;\n  color: #fff;\n}\n.MyVideodModal_wrapper__WtvNQ .MyVideodModal_close__5s8im:hover {\n  background: rgba(255, 255, 255, 0.402);\n}\n\n.MyVideodModal_video__nnnPZ {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/ModalVideo/MyVideodModal.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,2BAAA;EACA,aAAA;EACA,UAAA;AACJ;AAAI;EACI,kBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;AAER;AAAI;EACI,sCAAA;AAER;;AACA;EACI,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".wrapper {\n    position: relative;\n    background: rgb(70, 70, 70);\n    height: 450px;\n    padding: 0;\n    .close {\n        position: absolute;\n        cursor: pointer;\n        display: inline-block;\n        padding: 5px 10px;\n        border-radius: 3px;\n        top: 10px;\n        right: 10px;\n        color: #fff;\n    }\n    .close:hover {\n        background: rgba(255, 255, 255, 0.402);\n    }\n}\n.video {\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MyVideodModal_wrapper__WtvNQ",
	"close": "MyVideodModal_close__5s8im",
	"video": "MyVideodModal_video__nnnPZ"
};
export default ___CSS_LOADER_EXPORT___;
