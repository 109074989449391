import RatingStar from '../Rating/Rating';
import st from './FeedbackItem.module.scss'

const FeedbackItem = ({ fedd }) => {
    return (
        <div className={st.wrapper}>
            <div className={st.name}>{fedd.name}</div>
            <div className={st.rate}>
                <RatingStar variant='feedback' rate={fedd.rate} />
            </div>
            <div className={st.feedback}>{fedd.feedback}</div>
        </div>
    )
}
export default FeedbackItem;