import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Context } from '../..';
import { dropFeedback, fetchAllFeedbackNoCheck, updateFeedback } from '../../http/feedbackApi';
import st from './RowTableFeedback.module.scss'

const RowTableFeedback = observer(({ feed }) => {
    const { feedback } = useContext(Context)
    const publications = () => {
        updateFeedback(feed.id, true).then(data => alert('успешно'))
        fetchAllFeedbackNoCheck(1, 10).then(data => feedback.setCheckedFeedbackNoChecked(data.rows))
    }
    const dropFeed = () => {
        dropFeedback(feed.id).then(data =>
            fetchAllFeedbackNoCheck(1, 10).then(data => feedback.setCheckedFeedbackNoChecked(data.rows))
        )
    }
    return (
        <tr className={st.row} key={feed.id}>
            <td className={st.name} >{feed.name}</td>
            <td className={st.rate}>{feed.rate}</td>
            <td className={st.feedback} >{feed.feedback}</td>
            <td className={st.buttonsBlock}>
                <Button onClick={publications} variant='warning' size='sm' >Опубликовать</Button>
                <Button onClick={dropFeed} variant='danger' size='sm' >Удалить</Button>
            </td>
        </tr>
    )
})
export default RowTableFeedback;