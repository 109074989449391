import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Context } from '../..';
import st from './TypeBar.module.scss'

const TypeBar = observer(({ value }) => {
    const { item } = useContext(Context)
    const [menu, setMenu] = useState(false)

    const addMenuMobile = () => {
        setMenu(!menu)
        console.log(menu)
    }
    const selectType = (type) => {
        item.setSelectedType(type)
        setMenu(false)
    }

    return (
        <div className={st.wrapper}>
            <div className={st.header}>
                <span
                    onClick={addMenuMobile}
                    className={st.elem}>
                </span>
                <span onClick={() => item.setSelectedType({})} className={st.text}>Выберете тип техники</span>

                <div className={menu ? st.mobilTypeList + ' ' + st.mobilTypeListActive : st.mobilTypeList}>
                    {value.map(t =>
                        <div onClick={() => selectType(t)} key={t.id} className={item.selectedType.id === t.id ? st.itemBlockMobil + ' ' + st.itemBlockActiveMobil : st.itemBlockMobil}>
                            <div className={st.typeMobil}>{t.name}</div>
                        </div>
                    )}
                </div>

            </div>
            <div className={st.body}>
                {value.map(t =>
                    <div onClick={() => item.setSelectedType(t)} key={t.id} className={item.selectedType.id === t.id ? st.itemBlock + ' ' + st.itemBlockActive : st.itemBlock}>
                        <h4 className={st.type}>{t.name}</h4>
                        <div className={`${st.arrow} icon-arrow`}></div>
                    </div>
                )}
                <div onClick={() => item.setSelectedType({})} className={st.itemBlock}>
                    <div className={st.type + ' ' + st.fatchAll}>Показать все</div>
                    <div className={`${st.arrow} icon-arrow`}></div>
                </div>
            </div>
        </div>
    )
})
export default TypeBar;