import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Context } from '../..';
import BgItem from '../../components/BgItem/BgItem';
import CatalogList from '../../components/CatalogList/CatalogList';
import Paginator from '../../components/Paginator/Paginator';
import TypeBar from '../../components/TypeBar/TypeBar';
import { fetchAllItem, fetchTypes } from '../../http/itemApi';
import st from './Catalog.module.scss'

const Catalog = observer(() => {
    const { item } = useContext(Context)

    useEffect(() => {
        fetchTypes()
            .then(data => item.setTypes(data))
        fetchAllItem(null, 1, item.limit)
            .then(data => {
                item.setItems(data.rows)
                item.setTotalCount(data.count)
            })
    }, [item])

    useEffect(() => {
        fetchAllItem(item.selectedType.id, item.page, item.limit).then(data => {
            item.setItems(data.rows)
            item.setTotalCount(data.count)
        })
    }, [item, item.selectedType, item.page, item.limit])

    return (
        <div className={st.main}>
            <div className={st.contanier}>
                <div className={st.navigator}>
                    <TypeBar value={item.types} />
                </div>
                <div className={st.shopList}>
                    <CatalogList items={item.items} />
                    <Paginator />
                </div>
                <BgItem width='50%' style={{ top: '60%', left: '0%' }} />
                <BgItem style={{ top: '-10%', right: '0%' }} variant='car' />
            </div>
        </div>
    )
})
export default Catalog;