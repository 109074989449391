import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../..';
import CatalogList from '../../components/CatalogList/CatalogList';
import st from './SearchPage.module.scss'

const SearchPage = observer(() => {
    const { item } = useContext(Context)
    return (
        <div className={st.main}>
            <div className={st.contanier}>
                {
                    item.searchList.length < 1
                    ?
                    <div className={st.titleNo}>Ничего не найдено :(((</div>
                    :
                    <div className={st.title}>Результаты поиска</div>
                }
                
                <CatalogList forma={true} items={item.searchList} />
            </div>
        </div>
    )
})
export default SearchPage;