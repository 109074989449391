// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Paginator_wrapper__HFhzo {\n  margin: 0 auto;\n  display: flex;\n  gap: 5px;\n}\n.Paginator_wrapper__HFhzo .Paginator_pageItem__jpO-4 {\n  cursor: pointer;\n  width: 34px;\n  height: 34px;\n  border: 1px solid #4A4A4A;\n  border-radius: 14px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.Paginator_wrapper__HFhzo .Paginator_pageItem__jpO-4 span {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 102.2%;\n  letter-spacing: 0.045em;\n  color: #4A4A4A;\n}\n.Paginator_wrapper__HFhzo .Paginator_pageItemActive__Asilj {\n  background: #FFC123;\n  border: 1px solid #FFC123;\n}\n.Paginator_wrapper__HFhzo .Paginator_pageItemActive__Asilj span {\n  color: #ffffff;\n}", "",{"version":3,"sources":["webpack://./src/components/Paginator/Paginator.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,aAAA;EACA,QAAA;AACJ;AAAI;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAER;AADQ;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAGZ;AAAI;EACI,mBAAA;EACA,yBAAA;AAER;AAAQ;EACI,cAAA;AAEZ","sourcesContent":[".wrapper {\n    margin: 0 auto;\n    display: flex;\n    gap: 5px;\n    .pageItem {\n        cursor: pointer;\n        width: 34px;\n        height: 34px;\n        border: 1px solid #4A4A4A;\n        border-radius: 14px;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        span {\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 102.2%;\n            letter-spacing: 0.045em;\n            color: #4A4A4A;\n        }\n    }\n    .pageItemActive {\n        background: #FFC123;\n        border: 1px solid #FFC123;\n\n        span {\n            color: #ffffff;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Paginator_wrapper__HFhzo",
	"pageItem": "Paginator_pageItem__jpO-4",
	"pageItemActive": "Paginator_pageItemActive__Asilj"
};
export default ___CSS_LOADER_EXPORT___;
